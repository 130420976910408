<template>
  <div>
    <div align="center">
      <section class="e-content" align="left">
        <h2>
          Conclusion
          <img
            style="float: right; margin: 0 0 10pt 10pt; height: 100pt"
            src="../assets/obese_people_2.jpg"
          />
        </h2>
        <router-link to="/">☜ back to home</router-link><br /><br />
        <small>first image from the CDC<br />second image from source #1</small
        ><br />
        <img src="../assets/divider-1.gif" style="width: 100%" />
        <br /><br />
        <div style="text-indent: 15pt">
          <img
            src="../assets/cdc_graph.png"
            style="height: 180pt; margin: 10pt 0 10pt 10pt; float: right"
          />
          <p>
            When I think of mass diseases, I tend to picture developing
            countries with poor healthcare infrastructure and unsanitary
            environments. The obesity epidemic represents the opposite of that
            idea, a widespread disease almost exclusively found in developed
            countries. Unlike other diseases though, obesity is a chronic
            disease that is entirely preventable through informed decision
            making and public awareness. "Obesity meets all criteria for being a
            disease, and therefore should be characterized as such. This
            recognition impacts how individuals are viewed and should reduce the
            stigma associated with it. The statement is needed so that the
            public and policymakers can make informed decisions regarding the
            health care surrounding obesity." asserts Vice Pres. Cathy Kotz,
            professor and director of Graduate Studies at the University of
            Minnesota's Department of Integrative Biology and Physiology. The
            attitude surrounding obesity, at least in my experience, has been
            harsh and points most of the blame to the victim, citing lack of
            self control as the cause. Perhaps unsurprisingly, this way of
            thinking does very little in the way of preventing the spread of the
            disease, especially in children who have little to no control over
            their environment. “Too often, pediatric obesity is chalked up to
            ineffective parenting and/or lack of willpower on the part of the
            child. In fact, when obesity surfaces in childhood, it may reflect a
            particularly aggressive form of the disease likely driven by a
            strong biological component. Therefore, pediatric obesity should be
            taken seriously by all stakeholders.” emphasizes Aaron Kelly,
            codirector of the University of Minnesota's Center for Pediatric
            Obesity Medicine. It seems a much more effective method of
            preventing obesity is to simply spread awareness in an attempt to,
            like Kotz said, reduce the stigma associated with it. Feeling
            empowered while being obese is a positive first step in becoming a
            healther person, regardless of income or background.
          </p>
          <img
            src="../assets/gdp_graph.jpg"
            style="height: 350pt; margin: 10pt 10pt 10pt 0; float: left"
          />
          <p>
            Widening the scope a bit, the next thing I chose to look at was the
            link between national income and obesity. An analysis by the State
            University of New York used data from 147 countries to determine
            that there is a positive correlation between national income and
            obesity rates. The author of the study, Debabrata Talukdar, had this
            to say: “We find population obesity prevalence to exhibit a strong
            and persistent positive relationship with national income. As most
            people currently live in low- and middle-income countries with
            rising incomes, our findings underscore the urgent societal
            imperatives for effective policy initiatives, especially those that
            target the concomitant nutrition transition process with economic
            affluence, to break or at least weaken this positive relationship.”
            These findings reaffirm the narrative that obesity is a societal
            issue that goes deeper than just the individual. The analysis showed
            that for every 1 percent rise in a country’s GDP (per capita),
            obesity rates rise 1.23% in men and 1.01% in women. “This study
            predicts that global obesity rates will increase from 17.7 percent
            in 2014 to 21.6 percent in 2024, suggesting that policy initiatives
            could play an important role in combating obesity in low- and
            middle-income countries that are undergoing income growth.” adds
            Talukdar, further illustrating the concerning trend and calling on
            governments to step in. This seems to be a recurring theme, lack of
            policy, which makes sense as mass obesity at this level is a fairly
            recent phenomenon.
          </p>
          <p>
            While most of my research has pointed out the shortcomings of our
            society regarding obesity, there is some good news. Among low-income
            children, obesity rates are lower than their adult counterparts.
            This was surprising, considering low-income adults have the highest
            rates of obesity out of any income bracket, and you would think the
            same would be true for children. This study was conducted by the
            University of Maryland with the goal of determining whether federal
            food programs, including the Food Stamp and National School Lunch
            and Breakfast Programs, affected rates of obesity among low-income
            children. “Based on earlier research that shows that low-income
            adults have high obesity rates, we hypothesized that children from
            the lowest-income families would be more likely to be overweight. We
            also thought that the more money a family receives in Food Stamp
            Program benefits, the more likely the children would be overweight.”
            states Sandra Hofferth, co-author to the study. Of course, the
            results showed that children on these federal food programs were not
            any more likely to be overweight than those who weren’t. "These
            findings were counter to our hypotheses," she adds. This makes me
            optimistic about the next generation of low-income adults, perhaps
            the trend will continue downwards as this generation grows up.
          </p>
          <p>
            What I took away from this project was that humans aren’t perfect.
            Given the opportunity to indulge in their desires, even at great
            cost to their health, people will do it. This is evident by the
            connection between national GDP and obesity rates, as more food
            becomes available for cheaper people tend to get overweight.
            Especially for newly-developing countries, where people are moving
            out of the countryside and into cities where low-quality food is
            available en masse, plus it’s all so new and exciting. Even in a
            country as wealthy as the United States, little has been done from a
            policymakers perspective to prevent obesity from becoming an even
            bigger problem than it already is. Within the country, at least, the
            lines between income and obesity are becoming blurred as a new
            generation of low-income children grow up in a world where their
            parents are obese, but they aren’t. This doesn’t excuse the fact
            that very little policy exists in the way of promoting healthier
            lifestyles for adults, and if that doesn’t change, these low-income
            children are going to end up being the same obese adults we have
            today. The only way I see our obesity rates lowering is by reducing
            the stigma surrounding obesity and spreading awareness to people in
            positions of power.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "conclusion-page",
};
</script>

<style scoped></style>
